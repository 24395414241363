#root {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--primary-bg-color);
}

a, a:visited, a:active, a:hover{
  color: inherit;
  text-decoration: none;
  outline: 0;
}

/* hide the edges of the cartesian chart grid  */
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(2),
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:nth-last-child(2) {
  stroke-opacity: 0;
}
.recharts-wrapper {
  user-select: none;
}
